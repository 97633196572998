export class Configuration {

  // ---------------------------------------------------------------------------------------
  // URLS FOR PRODUCTION SERVER / PRODUCTION END POINTS
  // ---------------------------------------------------------------------------------------
  public static BaseAuthApiUrl: string =   "https://api.protoexpress-api.net/kiprod/token";
  public static BaseAuthApiUrl_Authorization: string =   "Basic 037c5c10-916b-11e8-a9a3-6710a465c191";
  public static BaseAppProtoexpressUrl: string =   "https://prodsuperapi.app.protoexpress.com/superAPI";
  public static BaseSQSUrl: string = "https://sqs.us-east-1.amazonaws.com/719945103823/sccdevd80252d6-69ea-4937-9619-6a626d1c4f18";
  public static BaseAppProtoexpressUrl_pcb_front_image: string =   "https://prodsuperapi.app.protoexpress.com/superAPI";
  public static BaseAppProtoexpressUrl_pcb_bottom_image: string =   "https://prodsuperapi.app.protoexpress.com/superAPI";
  public static BaseApiProtoexpressUrl: string =    "https://api.protoexpress-api.net/kiprod/account/profile";
  public static FS_BaseApiUrl: string =   "https://www.protoexpress.com/sierra-api/oauth/token";
  public static FS_BaseApiUrl_Authorization: string =  "Basic c2llcnJhLWFwaS1jbGllbnQtYmFzaWM6QzNBQzVFNkQtNkY5My00M0JBLUI0MDItREM1Q0QwODc1ODNF";
  public static FS_BaseApiUrl_username: string =   "custom-quote-tools";
  public static FS_BaseApiUrl_password: string =   "8C20291281FA7A2A8BE1D97E0C6DA1C1";
  public static FS_BaseApiUrl_grant_type: string =  "password";
  public static FS_CusQuoApiUrl: string = "https://www.protoexpress.com/sierra-api/femscreaterequest";
  public static FS_SubCustOrderApiUrl: string = "https://prodsuperapi.app.protoexpress.com/SubCustomOrd";
  public static FS_femsgetpdlistUrl: string = "https://www.protoexpress.com/sierra-api/femsgetpdlist";
  public static FS_femscreateprepartdataUrl: string = "https://www.protoexpress.com/sierra-api/femscreateprepartdata";
  public static dataDecryptUrl: string = "https://api.protoexpress-api.net/cqstage/decryption";
  public static femsGetUserProfileUrl: string = "https://www.protoexpress.com/sierra-api/account/profile";
  public static ssoRedirectionUrl: string = "https://www.protoexpress.com/chklogin.jsp?src=https://customquote.app.protoexpress.com/sso-response";
  public static logoClickUrl: string = "https://protoexpress.com";
  public static logoutClickUrl: string = "https://www.protoexpress.com/pcb/";

}
